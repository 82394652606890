.bg_image-web-development{
    background-image: url(/assets/images/web-development.webp) !important;
}

.active-dark .footer-area{
    background-color: #FFF;
}

@media only screen and (max-width: 575px){
    .choose-us-container {
        width: 450px;
    }
}
@media only screen and (max-width: 479px){
    .choose-us-container {
        width: 320px;
    }
}